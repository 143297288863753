import React, { useEffect, useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TentativeSources from "./components/TentativeSources";
import Response from "./components/Response";
import { useNavigate } from "react-router-dom";
import Examples from "./components/Examples";
import Page from "../components/Page";
import WorldMap from "../components/WorldMap";

interface SearchBarProps {
    query: string;
    setQuery: (query: string) => void;
    submit: () => void;
}

function SearchInput({ query, setQuery, submit }: SearchBarProps) {
    return (
        <input
            className="w-full bg-white/0 text-gray-800 focus:outline-none focus:ring-0 rounded ring-0 border-0 focus:boder-0 font-lato font-medium text-lg"
            type="search"
            enterKeyHint="go"
            placeholder="Start your query here"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    submit();
                }
            }}
        />
    );
}

function SearchBar({ query, setQuery, submit }: SearchBarProps) {
    return (
        <div className="">
            <div className="md:bg-white bg-white/90 hover:bg-white focus-within:bg-white whiteHalo rounded-full border h-12 flex items-center px-4 border-gray-200 hover:shadow-lg focus-within:shadow-lg z-50 transition-shadow duration-300 ease-in-out">
                <div className="flex flex-row items-center gap-4 font-merriweather w-full">
                    <FontAwesomeIcon className="text-gray-400" icon={faMagnifyingGlass} />
                    <SearchInput query={query} setQuery={setQuery} submit={submit} />
                </div>
            </div>
        </div>
    );
}

async function readJson(reader: ReadableStreamDefaultReader<string>): Promise<any> {
    let json_str = "";
    while (true) {
        const { done, value } = await reader.read();

        if (done) {
            throw new Error("reached the end of stream without finding [JSON DONE]");
        }

        json_str += value;

        if (json_str.includes("[JSON_DONE]")) {
            break;
        }
    }

    // split the json_str to string before [JSON DONE] and string after [JSON DONE]
    const [before, after] = json_str.split("[JSON_DONE]", 2);
    return {
        data: JSON.parse(before),
        extra: after,
    };
}

async function readResponseStream(reader: ReadableStreamDefaultReader<string>, appendResponse: (str: string) => void) {
    while (true) {
        const { done, value } = await reader.read();

        if (done) {
            break;
        }

        appendResponse(value);
    }
}


export default function SearchPage() {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const queryParam = params.get("query") || "";

    const [query, setQuery] = useState(queryParam);
    const [LLMSources, setLLMSources] = useState<{ [key: string]: string }>({});
    const [LLMResponse, setLLMResponse] = useState<string>("");
    const [answerLoading, setAnswerLoading] = useState<boolean>(false);

    const appendResponse = (str: string) => {
        setLLMResponse((prev) => (prev + str).trimStart());
    };

    const submit = () => {
        window.gtag("event", "submit_search", {
            value: query,
        });
        // navigate to the same page with the query param
        navigate("/?query=" + query);
        navigate(0);
    };

    useEffect(() => {
        const loadAnswer = (query: string) => {
            setAnswerLoading(true);
            setLLMSources({});
            setLLMResponse("");

            window.gtag("event", "load_answer", {
                value: query,
            });

            const api_url = process.env.REACT_APP_API_URL;
            const url = api_url + "/wiki-query?query=" + query + "&use_fake_data=false";

            fetch(url)
                .then((response) => {
                    const stream = response.body?.pipeThrough(new TextDecoderStream());
                    const reader = stream?.getReader();
                    return reader;
                })
                .then(async (reader) => {
                    if (reader === undefined) {
                        return;
                    }
                    const { data, extra } = await readJson(reader);

                    setLLMSources(data.sources);

                    setLLMResponse(extra.trimStart());
                    await readResponseStream(reader, appendResponse);
                })
                .catch((e) => {
                    // do nothing
                });
        };

        if (queryParam !== "") {
            loadAnswer(queryParam);
        }
    }, [queryParam]);

    return (
        <Page>
            <WorldMap />
            <div className="w-full justify-center flex mb-16">
            {/* <h1 className="font-sarabun text-3xl text-gray-900"> Wiki Find </h1> */}
            <div className="flex flex-col gap-2 w-fit whiteHalo justify-center items-center">
                <h3 className="font-sarabun text-lg md:text-xl text-gray-800"> AI powered search engine for Wikipedia. </h3>
                <h4 className="font-sarabun text-md md:text-lg text-gray-800"> No hallucinations and always up to date. </h4>
            </div>
            </div>
            <SearchBar query={query} setQuery={setQuery} submit={submit} />
            <TentativeSources
                tentativeSources={Object.values(LLMSources)}
                visible={LLMResponse === "" && answerLoading}
            />
            <Response response={LLMResponse} sourcesNameMap={LLMSources} />
            <Examples visible={LLMResponse === "" && !answerLoading} />
        </Page>
    );
}
