import React from 'react';
import './App.css';
import SearchPage from './SearchPage/SearchPage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import FAQ from './FAQ';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SearchPage />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
