import React, { useState } from "react";

function Example({ query }: { query: string }) {
    return (
        <div>
            <a
                href={"/?query=" + query}
            >
                <div className="px-3 py-1 bg-blue-100 rounded-full hover:shadow-lg border border-blue-300 hover:bg-blue-200">
                <span className="font-lato font-medium  text-gray-700 align-middle text-center flex">
                    {query}
                </span>
                </div>
            </a>
        </div>
    );
}

export default function Examples({ visible }: { visible: boolean }) {
    const examples = [
        "What is the capital of France?",
        "Which city has larger population, New York or San Francisco?",
        "When did Leonardo da Vinci live?",
        "Who painted the Mona Lisa?",
        "Where is Lesotho?",
        "What are the neighbouring countries of Azerbaijan?",
        "What is Python?",
        "Which company is older, Nikon or Sony?",
    ];

    const [randomThreeExamples] = useState<string[]>(
        examples.sort(() => Math.random() - Math.random()).slice(0, 3)
    );

    if (!visible) {
        return null;
    }

    return (
        <div className="mt-8 flex flex-col justify-center items-center">
            <h3 className="font-lato text-lg mb-2 md:bg-white bg-white/75 whiteHalo"> Or explore one of our examples </h3>
            <div className="flex flex-col gap-4 items-center">
                {randomThreeExamples.map((example: string, index: number) => (
                    <Example key={index} query={example} />
                ))}
            </div>
        </div>
    );
}
