import BuyMeACoffee from "../images/bmc-logo-no-background.png";

export default function Footer() {
    return (
        <div className="mt-16 pt-4 flex border-t border-gray-200 justify-center flex-col items-center">
            <span>
                made with ❤️ by{" "}
                <a href="https://martinpiala.com" rel="noreferrer" target="_blank" className="underline">
                    Martin Piala
                </a>
            </span>
            <span>
                support me via{" "}
                <a href="https://www.buymeacoffee.com/mackopes" target="_blank" rel="noreferrer"
                    className="ml-1 font-cookie text-xl">
                    <img src={BuyMeACoffee} className="inline h-6" alt=""/>{" "}
                    buy me a coffee
                </a>
            </span>
        </div>
    );
}
