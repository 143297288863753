import Footer from "./Footer";
import Header from "./Header";


export default function Page({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex w-screen justify-center pt-4 pb-8 min-h-screen relative">
            <div className="w-full max-w-xl px-4 lg:px-0 flex flex-col">
                <Header/>
                <main className="flex-grow">
                    {children}
                </main>
                <Footer/>
            </div>
        </div>
    );
}