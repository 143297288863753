import React from "react";

function Paragraph({ paragraph }: { paragraph: string }) {
    return <p className="text-gray-500 font-lato font-medium text-lg"> {paragraph} </p>;
}


function Information({ information }: { information: string }) {
    if (information === undefined || information === "") {
        return null;
    }

    const paragraphs = information.trim().split("<BR>").filter((paragraph: string) => paragraph !== "");

    return (
        <div className="flex flex-col gap-2">
            <h3 className="font-lato text-lg"> Answer </h3>
            {paragraphs.map((paragraph: string, index: number) => (
                <Paragraph key={index} paragraph={paragraph} />
            ))}
        </div>
    )

    // return <span className=""> {information} </span>;
}

function Source({ source, sourcesNameMap = {} }: { source: string; sourcesNameMap?: { [key: string]: string } }) {
    let name = source;
    if (source in sourcesNameMap) {
        name = sourcesNameMap[source];
    }

    return (
        <a href={source} target="_blank" rel="noreferrer" className="border border-gray-200 rounded-xl px-4 py-2 font-lato font-light text-md flex flex-row items-center hover:bg-gray-50">
            <img src="https://upload.wikimedia.org/wikipedia/commons/5/5a/Wikipedia%27s_W.svg" alt="Wikipedia Icon" className="w-6 h-6 mr-2" />
            <span> {name} </span>
        </a>
    )
}

function Sources({
    sourcesText,
    sourcesNameMap = {},
}: {
    sourcesText: string;
    sourcesNameMap?: { [key: string]: string };
}) {
    if (sourcesText === undefined || sourcesText === "") {
        return null;
    }

    const sources = sourcesText.trim().split(", ").filter((source: string) => source !== "");

    if (sources.length === 0) {
        return null;
    }

    return (
        <div className="">
            <h3 className="font-lato mb-2 text-lg"> Sources (experimental) </h3>
            <div className="flex flex-col gap-2">
                {sources.map((source: string, index: number) => (
                    <Source key={index} source={source} sourcesNameMap={sourcesNameMap} />
                ))}

            </div>
        </div>
    );
}

export default function Response({
    response,
    sourcesNameMap = {},
}: {
    response: string;
    sourcesNameMap?: { [key: string]: string };
}) {
    const [information, sourcesText] = response.split("SOURCES:", 2);

    return (
        <div className="mt-4 flex flex-col gap-8 bg-white whiteHalo">
            <Information information={information} />
            <Sources sourcesText={sourcesText} sourcesNameMap={sourcesNameMap} />
        </div>
    );
}
