"use client";

import { Canvas, useFrame } from "@react-three/fiber";
import { Instances, Instance } from "@react-three/drei";
import { Vector3 } from "three";
// import { useSpring, animated } from "@react-spring/three";
import { MutableRefObject, useRef } from "react";

export function floatingAnimation(ref: MutableRefObject<undefined>, state: any, position: Vector3, animationOffset: number) {
    const { clock } = state;
    const time = clock.getElapsedTime();
    // console.log(time);
    const param = time / 4 + animationOffset*100;
    const factor = (Math.sin(param) + Math.sin(4*param) + Math.sin(8*param)) * 0.5 + 0.5;
    const current = ref.current as any;

    current.position.y = position.y + factor * 1.5;
    current.position.x = position.x + factor * 0.5;
}

function Circle({
    position,
    materialColor,
    animationOffset,
    animation = () => {},
}: {
    position: Vector3;
    materialColor: string;
    animationOffset: number;
    animation?: (ref: MutableRefObject<undefined>, state: any, position: Vector3, animationOffset: number) => void;
}) {
    const ref = useRef();

    useFrame((state) => {
        animation(ref, state, position, animationOffset);
    });

    return <Instance position={position} color={materialColor} ref={ref} />;;
}

export class CircleData {
    position: [number, number]
    animationOffset: number;
    color: string;

    constructor(position: [number, number], animationOffset: number, color: string) {
        this.position = position;
        this.animationOffset = animationOffset;
        this.color = color;
    }
}

interface DotMatrixProps {
    circleData: CircleData[],
    gap: number;
    radius: number;
    animation? : (ref: MutableRefObject<undefined>, state: any, position: Vector3, animationOffset: number) => void;
}

export default function DotMatrix({
    circleData,
    gap,
    radius,
    animation = () => {},
}: DotMatrixProps) {
    const circles = [];
    const maxX = Math.max(...circleData.map((circle) => circle.position[0]));
    const minX = Math.min(...circleData.map((circle) => circle.position[0]));

    const maxY = Math.max(...circleData.map((circle) => circle.position[1]));
    const minY = Math.min(...circleData.map((circle) => circle.position[1]));

    const width = maxX - minX + 1;
    const height = maxY - minY + 1;

    console.log(width);

    const offset = radius * 2 + gap;

    for (let i = 0; i < circleData.length; i++) {
        circles.push({
            position: new Vector3(
                (circleData[i].position[0] - minX)* offset - (width * offset) / 2,
                (circleData[i].position[1] - minY) * offset - (height * offset) / 2,
                0
            ),
            animationOffset: circleData[i].animationOffset,
            color: circleData[i].color,
        });
    }

    console.log(circles);


    return (
        <Canvas camera={{ fov: 75, position: [0, 0, 10] }} orthographic={true}>
            <ambientLight />
            <Instances count={circles.length}>
                <circleGeometry args={[radius, 16]} />
                <meshStandardMaterial />
                {circles.map((circle, index) => (
                    <Circle position={circle.position} materialColor={circle.color} animationOffset={circle.animationOffset} key={index} animation={animation}/>
                ))}
            </Instances>
        </Canvas>
    );
}
