import Page from "./components/Page";

function Question({ children }: { children: React.ReactNode }) {
    return <h2 className="w-full font-lato font-bold text-xl text-gray-800 mt-2 mb-2">{children}</h2>;
}

function Answer({ children }: { children: React.ReactNode }) {
    return <p className="w-full font-lato font-medium text-md text-gray-600 mb-2">{children}</p>;
}

export default function FAQ() {
    return (
        <Page>
            <h1 className="w-full justify-center flex font-sarabun text-3xl"> FAQ </h1>
            <div className="flex flex-col items-center">
                <Question> What is WikiFind? </Question>
                <Answer>
                    WikiFind is a GPT-powered Wikipedia answer engine. WikiFind not only finds related sources, but also
                    tries to answer the question.
                </Answer>

                <Question> Why do I need WikiFind when I have ChatGPT? </Question>
                <Answer>
                    ChatGPT's text-generating capabilities are impressive, but it falls short in answering factual
                    questions.
                </Answer>
                <Answer>
                    Even though ChatGPT understands a wide range of topics, it is prone to hallucinations. When ChatGPT
                    is not sure with the answer, it will make it up. This really hurts the credibility of the bot.
                </Answer>
                <Answer>
                    Moreover, ChatGPT's training data only extends to the year 2021, which limits its knowledge of
                    recent events.
                </Answer>
                <Answer>
                    Fortunately, both of these issues can be addressed by providing the model with an up to date source
                    of information. Assuimng Wikipedia is both trustworthy and up to date, giving model relevant
                    Wikipedia articles prevents hallucinations and gives the model access to the latest information.
                </Answer>

                <Question> When should I use this over Google? </Question>
                <Answer>
                    Whenever you find yourself Googling a topic just to navigate to Wikipedia, you can use Wikifind
                    instead!
                </Answer>

                <Question> Why should I donate? </Question>
                <Answer>
                    Wikifind is a free service, and will always be free. To honour the mission of Wikipedia, I'll never
                    serve ads on WikiFind either.
                </Answer>
                <Answer>
                    However, large language models like ChatGPT are expensive to run. If you consider WikiFind useful,
                    please consider <a href="https://www.buymeacoffee.com/mackopes" target="_blank" rel="noreferrer" className="underline">donating</a> to help me keep the lights on.
                </Answer>
            </div>
        </Page>
    );
}
