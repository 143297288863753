import {ReactComponent as Logo} from '../assets/wiki-find-logo.svg';

export default function Header() {
    return (
        <div className="w-full flex flex-row justify-between h-12 items-center p-4 mb-4">
            <a href="/">
                {/* <h1 className="font-sarabun text-lg text-gray-800"> Wiki Find </h1> */}
                <Logo className="w-24 h-24" />
            </a>
            <a href="/faq">
                <h3 className="font-sarabun text-lg font-light text-gray-800"> FAQ </h3>
            </a>
        </div>
    );
}