"use client";

import { useEffect, useMemo, useState } from "react";
import DotMatrix, { CircleData, floatingAnimation } from "./dot_matrix";

function buildCircles() {
    const POSITIONS: [number, number][] = [
        // [-w, -h],
        // [w, h],

        // NORTH AMERICA
        [-35, 14],
        [-33, 14],
        [-33, 13],
        [-32, 14],
        [-32, 13],
        [-32, 12],
        [-31, 15],
        [-31, 14],
        [-31, 13],
        [-30, 14],
        [-30, 13],
        [-29, 14],
        [-29, 13],
        [-28, 14],
        [-28, 13],
        [-27, 14],
        [-27, 13],
        [-27, 12],
        [-26, 14],
        [-26, 13],
        [-26, 12],
        [-26, 11],
        [-25, 15],
        [-25, 14],
        [-25, 13],
        [-25, 12],
        [-25, 11],
        [-24, 16],
        [-24, 14],
        [-24, 13],
        [-24, 12],
        [-24, 11],
        [-24, 10],
        [-24, 9],
        [-24, 8],
        [-23, 15],
        [-23, 14],
        [-23, 13],
        [-23, 12],
        [-23, 11],
        [-23, 10],
        [-23, 9],
        [-23, 8],
        [-23, 7],
        [-22, 16],
        [-22, 15],
        [-22, 14],
        [-22, 13],
        [-22, 12],
        [-22, 11],
        [-22, 10],
        [-22, 9],
        [-22, 8],
        [-22, 7],
        [-22, 6],
        [-22, 5],
        [-21, 15],
        [-21, 14],
        [-21, 13],
        [-21, 12],
        [-21, 11],
        [-21, 10],
        [-21, 9],
        [-21, 8],
        [-21, 7],
        [-21, 6],
        [-20, 16],
        [-20, 14],
        [-20, 13],
        [-20, 12],
        [-20, 11],
        [-20, 10],
        [-20, 9],
        [-20, 8],
        [-20, 7],
        [-20, 6],
        [-20, 5],
        [-20, 4],
        [-19, 15],
        [-19, 14],
        [-19, 13],
        [-19, 12],
        [-19, 11],
        [-19, 10],
        [-19, 9],
        [-19, 8],
        [-19, 7],
        [-19, 4],
        [-18, 17],
        [-18, 16],
        [-18, 14],
        [-18, 12],
        [-18, 11],
        [-18, 10],
        [-18, 9],
        [-18, 8],
        [-18, 7],
        [-18, 4],
        [-18, 3],
        [-17, 17],
        [-17, 16],
        [-17, 15],
        [-17, 14],
        [-17, 11],
        [-17, 10],
        [-17, 9],
        [-17, 8],
        [-17, 7],
        [-17, 4],
        [-17, 3],
        [-16, 17],
        [-16, 16],
        [-16, 15],
        [-16, 14],
        [-16, 11],
        [-16, 10],
        [-16, 9],
        [-16, 8],
        [-16, 7],
        [-16, 6],
        [-16, 2],
        [-15, 17],
        [-15, 15],
        [-15, 14],
        [-15, 12],
        [-15, 11],
        [-15, 10],
        [-15, 9],
        [-15, 8],
        [-15, 4],
        [-14, 17],
        [-14, 14],
        [-14, 12],
        [-14, 11],
        [-14, 10],
        [-14, 9],
        [-13, 17],
        [-13, 14],
        [-13, 12],
        [-13, 11],
        [-13, 10],
        [-12, 17],
        [-12, 16],
        [-12, 12],
        [-12, 11],
        [-11, 16],
        [-11, 11],
        [-11, 10],
        [-10, 16],
        [-10, 15],
        [-9, 16],
        [-9, 15],
        [-9, 14],
        [-9, 13],
        [-8, 17],
        [-8, 16],
        [-8, 15],
        [-8, 14],
        [-7, 17],
        [-7, 16],
        [-7, 15],
        [-7, 14],
        [-6, 17],
        [-6, 16],
        [-6, 15],
        [-5, 17],
        [-5, 16],
        [-5, 15],
        [-4, 16],

        // SOUTH AMERICA
        [-16, -1],
        [-15, 2],
        [-15, 1],
        [-15, 0],
        [-15, -1],
        [-15, -2],
        [-14, 3],
        [-14, 2],
        [-14, 1],
        [-14, 0],
        [-14, -1],
        [-14, -2],
        [-14, -3],
        [-14, -7],
        [-14, -8],
        [-14, -9],
        [-14, -10],


        [-13, 2],
        [-13, 1],
        [-13, 0],
        [-13, -1],
        [-13, -2],
        [-13, -3],
        [-13, -4],
        [-13, -5],
        [-13, -6],
        [-13, -7],
        [-13, -8],
        [-13, -9],
        [-13, -11],
        [-12, 2],
        [-12, 1],
        [-12, 0],
        [-12, -1],
        [-12, -2],
        [-12, -3],
        [-12, -4],
        [-12, -5],
        [-12, -6],
        [-12, -7],
        [-12, -8],
        [-11, 1],
        [-11, 0],
        [-11, -1],
        [-11, -2],
        [-11, -3],
        [-11, -4],
        [-11, -5],
        [-11, -6],
        [-11, -7],
        [-10, 1],
        [-10, 0],
        [-10, -1],
        [-10, -2],
        [-10, -3],
        [-10, -4],
        [-10, -5],
        [-10, -6],
        [-9, 0],
        [-9, -1],
        [-9, -2],
        [-9, -3],
        [-9, -4],
        [-8, -1],
        [-8, -2],
        [-8, -3],
        [-8, -4],
        [-7, -1],
        [-7, -2],

        // EUROPE
        [-3, 14],
        [-1, 11],
        [-1, 9],
        [-1, 8],
        [0, 12],
        [0, 11],
        [0, 10],
        [0, 9],
        [0, 8],
        [1, 10],
        [1, 9],
        [2, 13],
        [2, 11],
        [2, 10],
        [3, 13],
        [3, 12],
        [3, 11],
        [3, 10],
        [3, 9],
        [4, 16],
        [4, 14],
        [4, 13],
        [4, 11],
        [4, 10],
        [4, 9],
        [4, 8],
        [5, 16],
        [5, 14],
        [5, 12],
        [5, 11],
        [5, 10],
        [5, 9],
        [5, 8],
        [6, 15],
        [6, 14],
        [6, 13],
        [6, 12],
        [6, 11],
        [6, 10],
        [6, 9],
        [6, 8],
        [7, 14],
        [7, 13],
        [7, 12],
        [7, 11],
        [7, 10],
        [7, 8],
        [8, 14],
        [8, 13],
        [8, 12],
        [8, 11],
        [8, 10],
        [8, 8],
        [9, 14],
        [9, 13],
        [9, 12],
        [9, 11],
        [9, 10],
        [9, 8],
        [10, 14],
        [10, 13],
        [10, 12],
        [10, 11],
        [10, 10],
        [10, 9],
        [10, 8],

        // AFRICA
        [-3, 3],
        [-2, 6],
        [-2, 5],
        [-2, 4],
        [-2, 3],
        [-2, 2],
        [-1, 7],
        [-1, 6],
        [-1, 5],
        [-1, 4],
        [-1, 3],
        [-1, 2],
        [0, 7],
        [0, 6],
        [0, 5],
        [0, 4],
        [0, 3],
        [0, 2],
        [1, 7],
        [1, 6],
        [1, 5],
        [1, 4],
        [1, 3],
        [1, 2],
        [2, 8],
        [2, 7],
        [2, 6],
        [2, 5],
        [2, 4],
        [2, 3],
        [2, 2],
        [3, 7],
        [3, 6],
        [3, 5],
        [3, 4],
        [3, 3],
        [3, 2],
        [3, 1],
        [3, 0],
        [3, -3],
        [4, 6],
        [4, 5],
        [4, 4],
        [4, 3],
        [4, 2],
        [4, 1],
        [4, 0],
        [4, -1],
        [4, -2],
        [4, -3],
        [4, -4],
        [4, -5],
        [5, 7],
        [5, 6],
        [5, 5],
        [5, 4],
        [5, 3],
        [5, 2],
        [5, 1],
        [5, 0],
        [5, -1],
        [5, -2],
        [5, -3],
        [5, -4],
        [5, -5],
        [5, -6],
        [6, 6],
        [6, 5],
        [6, 4],
        [6, 3],
        [6, 2],
        [6, 1],
        [6, 0],
        [6, -1],
        [6, -2],
        [6, -3],
        [6, -4],
        [6, -5],
        [6, -6],
        [7, 6],
        [7, 5],
        [7, 4],
        [7, 3],
        [7, 2],
        [7, 1],
        [7, -1],
        [7, -2],
        [7, -3],
        [7, -4],
        [7, -5],
        [8, 7],
        [8, 6],
        [8, 4],
        [8, 3],
        [8, 2],
        [8, 1],
        [8, -1],
        [8, -2],
        [8, -3],
        [9, 7],
        [9, 6],
        [9, 5],
        [9, 3],
        [9, 2],
        [9, 1],
        [9, 0],
        [10, 7],
        [10, 6],
        [10, 5],
        [10, 4],
        [10, 2],
        [10, 1],
        [10, -3],
        [10, -4],
        [11, 6],
        [11, 5],
        [11, 4],
        [12, 5],

        // ASIA
        [11, 14],
        [11, 13],
        [11, 12],
        [11, 11],
        [11, 10],
        [11, 7],
        [12, 15],
        [12, 14],
        [12, 13],
        [12, 12],
        [12, 11],
        [12, 10],
        [12, 9],
        [12, 8],
        [12, 7],
        [12, 6],
        [13, 16],
        [13, 14],
        [13, 13],
        [13, 12],
        [13, 11],
        [13, 10],
        [13, 8],
        [13, 7],
        [13, 6],
        [14, 16],
        [14, 14],
        [14, 13],
        [14, 12],
        [14, 11],
        [14, 10],
        [14, 9],
        [14, 8],
        [14, 7],
        [14, 6],
        [15, 15],
        [15, 14],
        [15, 13],
        [15, 12],
        [15, 11],
        [15, 10],
        [15, 9],
        [15, 8],
        [15, 7],
        [15, 6],
        [15, 5],
        [16, 14],
        [16, 13],
        [16, 12],
        [16, 11],
        [16, 10],
        [16, 9],
        [16, 8],
        [16, 7],
        [16, 6],
        [16, 5],
        [16, 4],
        [16, 3],
        [17, 15],
        [17, 14],
        [17, 13],
        [17, 12],
        [17, 11],
        [17, 10],
        [17, 9],
        [17, 8],
        [17, 7],
        [17, 6],
        [17, 5],
        [17, 4],
        [18, 15],
        [18, 14],
        [18, 13],
        [18, 12],
        [18, 11],
        [18, 10],
        [18, 9],
        [18, 8],
        [18, 7],
        [18, 6],
        [18, 5],
        [19, 15],
        [19, 14],
        [19, 13],
        [19, 12],
        [19, 11],
        [19, 10],
        [19, 9],
        [19, 8],
        [19, 7],
        [19, 5],
        [20, 17],
        [20, 15],
        [20, 14],
        [20, 13],
        [20, 12],
        [20, 11],
        [20, 10],
        [20, 9],
        [20, 8],
        [20, 7],
        [20, 6],
        [20, 5],
        [20, 4],
        [21, 16],
        [21, 15],
        [21, 14],
        [21, 13],
        [21, 12],
        [21, 11],
        [21, 10],
        [21, 9],
        [21, 8],
        [21, 7],
        [21, 6],
        [21, 5],
        [21, 4],
        [21, 3],
        [22, 16],
        [22, 15],
        [22, 14],
        [22, 13],
        [22, 12],
        [22, 11],
        [22, 10],
        [22, 9],
        [22, 8],
        [22, 7],
        [22, 6],
        [22, 5],
        [22, 4],
        [22, 3],
        [23, 15],
        [23, 14],
        [23, 13],
        [23, 12],
        [23, 11],
        [23, 10],
        [23, 9],
        [23, 8],
        [23, 7],
        [23, 6],
        [23, 5],
        [24, 15],
        [24, 14],
        [24, 13],
        [24, 12],
        [24, 11],
        [24, 10],
        [24, 9],
        [24, 8],
        [24, 7],
        [24, 6],
        [24, 5],
        [25, 14],
        [25, 13],
        [25, 12],
        [25, 11],
        [25, 10],
        [25, 9],
        [25, 7],
        [25, 6],
        [26, 15],
        [26, 14],
        [26, 13],
        [26, 12],
        [26, 11],
        [26, 10],
        [26, 9],
        [26, 8],
        [27, 14],
        [27, 13],
        [27, 12],
        [27, 11],
        [27, 10],
        [27, 9],
        [27, 7],
        [28, 15],
        [28, 14],
        [28, 13],
        [28, 12],
        [28, 11],
        [28, 10],
        [28, 7],
        [29, 15],
        [29, 14],
        [29, 13],
        [29, 11],
        [29, 8],
        [30, 14],
        [30, 13],
        [31, 14],
        [31, 13],
        [32, 15],
        [32, 14],
        [32, 13],
        [33, 14],
        [33, 13],
        [33, 11],
        [34, 14],
        [34, 13],
        [34, 12],
        [35, 14],
        [35, 13],

        // INDONESIA
        [20, 1],
        [21, 1],
        [21, 0],
        [22, 0],
        [22, -1],
        [23, 0],
        [23, -1],
        [24, 1],
        [24, -1],
        [25, 2],
        [25, 0],
        [25, -1],
        [26, 3],
        [26, 2],
        [26, 0],
        [27, 0],
        [28, 0],
        [29, 0],
        [29, -1],

        // AUSTRALIA
        [24, -5],
        [24, -6],
        [25, -4],
        [25, -5],
        [25, -6],
        [26, -3],
        [26, -4],
        [26, -5],
        [26, -6],
        [27, -3],
        [27, -4],
        [27, -5],
        [28, -2],
        [28, -3],
        [28, -4],
        [28, -5],
        [28, -6],
        [29, -4],
        [29, -5],
        [29, -6],
        [29, -7],
        [30, -3],
        [30, -4],
        [30, -5],
        [30, -6],
        [30, -7],
        [30, -9],
        [31, -4],
        [31, -5],
        [31, -6],
        [34, -9],
        [35, -8],
    ];

    const colors = [
        "#60A5FA","#38BDF8", "#06B6D4"
    ]

    const circles = POSITIONS.map((pos) => {
        const color = colors[Math.floor(Math.random() * colors.length)];
        return new CircleData(pos, Math.random(), color);
    });

    return circles;
}

function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight
    return {
        width,
        height
    };
}

export default function WorldMap() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let gap = 3;
    let radius = 4;

    if (windowDimensions.width < 768) {
        gap = 1;
        radius = 2;
    }



    // const w = 35;
    // const h = 17;

    const circles = useMemo(() => buildCircles(), []);


    return (
        <div className="w-full absolute flex justify-center top-10 md:top-32 -left-4 md:-left-8 -z-10 brightness-[1.2] md:brightness-[1.32]">
            <div className="w-[800px] h-[400px]">
            {/* <div className="w-[400px] h-[187px]"> */}
                <DotMatrix circleData={circles} gap={gap} radius={radius} animation={floatingAnimation}  />
            </div>
        </div>
    );
}
