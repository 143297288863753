import React from "react";

function LoadingBar() {
    const pulseClass = "bg-gradient-to-r from-white/0 from-0% via-white/100 via-70% via-white/100 via-80% to-white/0 to-100% opacity-30 animate-transformLeftToRight";
    return (
        <div className="w-full relative h-6">
            <div className="w-full h-6 flex bg-gray-300 rounded absolute"> </div>
            <div className={`w-1/3 h-6 flex rounded absolute ${pulseClass}`}/>
        </div>

    )
}

function TentativeSource({ source, index, selected }: { source: string, index: number, selected: number }) {
    const charLimit = 40;
    if (source.length > charLimit) {
        source = source.substring(0, charLimit) + "...";
    }

    const additionalStyle = index === selected ? "animate-in fade-in slide-in-from-bottom-6 duration-700" :
        "animate-out fade-out slide-out-to-top-12 duration-300 collapse";
    return (
        <span
            className={`bg-blue-100 border-blue-300 border whitespace-nowrap text-gray-800 font-merriweather font-light px-4 py-0.5 rounded-full absolute top-0 -translate-y-1/2 ${additionalStyle}`}
        >
            {source}
        </span>
    );
}

export default function TentativeSources({
    tentativeSources,
    visible = true,
}: {
    tentativeSources: string[];
    visible: boolean;
}) {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const moveNext = () => {
        if (tentativeSources.length === 0) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex((currentIndex + 1) % tentativeSources.length);
        }
    };

    React.useEffect(() => {
        const interval = setInterval(moveNext, 3500);
        return () => clearInterval(interval);
    });

    if (!visible) {
        return null;
    }

    const pills = tentativeSources.map((source, index) => {
        return (
            <TentativeSource key={index} source={source} index={index} selected={currentIndex} />
        );
    });

    if (pills.length === 0) {
        return (
            <div className="mt-4">
                <LoadingBar />
            </div>
        )
    }

    return (
        <div className="mt-4 flex flex-row items-center gap-2">
            <span className="font-lato font-light"> Reading about </span>
            <div className="flex flex-col w-fit h-fit relative">
                {pills}
            </div>
        </div>
    );
}
